import axios from "lib/axios";

export const getCompany = () => {
  return axios.get(`/info/company`);
};

export const updateCompany = (data) => {
  return axios.put(`/info/company`, data);
};

export const updateCompanyWhiteLabeling = (formData) => {
  return axios.postForm(`/info/company/white_labeling`, formData);
};

export const getSamlSpConfig = (provider) => {
  return axios.get(`/info/company/saml/sp/${provider}`);
};

export const getProviderConfig = (provider) => {
  return axios.get(`/info/company/saml/${provider}`);
};

export const updateProviderConfig = (provider, formData) => {
  return axios.putForm(`/info/company/saml/${provider}`, formData);
};

export const getSpMetafile = (provider) => {
  return axios.get(`/info/company/saml/sp/${provider}/metadata`, {responseType: "blob"});
};