const reducer = (pagination, defaults = {}) => {
  const initialState = {
    page: 0,
    pagesize: 10,
    orderby: "",
    search: "",
    extra_filters: [],
    timestamp: null,
    ...defaults,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case pagination.PAGE:
        return {
          ...state,
          page: action.data,
        };
      case pagination.PAGE_SIZE:
        return {
          ...state,
          page: 0,
          pagesize: action.data,
        };
      case pagination.ORDER:
        return {
          ...state,
          page: 0,
          orderby: action.data,
        };
      case pagination.SEARCH:
        return {
          ...state,
          page: 0,
          search: action.data,
        };
      case pagination.FILTERS:
        return {
          ...state,
          page: 0,
          extra_filters: action.data,
        };
      case pagination.RESET:
        return initialState;
      default:
        return state;
    }
  };
};

export default reducer;