import classNames from "classnames";
import strings from "l10n";
import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";
import { isFunction } from "utils";

import "./table.scss";

const getColumnName = (column, data) =>
  isFunction(column.name) ? column.name(data) : column.name;

const Cell = ({ data, column }) => {
  const columnName = getColumnName(column, data);

  return (
    <td
      className={classNames(`col-${columnName}`, column.className)}
      style={column.style}
    >
      {column.getter(data)}
    </td>
  );
};

const Row = ({ data, columns, itemLink, nestingLevel }) => {
  const expandable = data?.num_children > 0;
  const { expanded } = data;
  const visibleColumns = columns.filter((it) => it.visible);
  const trans = strings.components.table;

  return (
    <>
      <tr
        className={classNames(
          { expandable, expanded },
          `nested-${Math.min(nestingLevel, 5)}`,
          { nested: nestingLevel && nestingLevel > 0 },
        )}
      >
        {visibleColumns.map((col) => (
          <Cell key={col.name} data={data} column={col} />
        ))}
      </tr>

      {expanded
        ? renderRow(
            data?.children?.results,
            columns,
            itemLink,
            nestingLevel + 1,
          )
        : null}

      {expanded && data?.children?.next ? (
        <tr className="text-center">
          <td colSpan={visibleColumns.length}>
            <Link to={itemLink(data)} target="_blank" rel="noreferrer noopener">
              <small>
                {strings.formatString(
                  trans.moreItems,
                  <b>{data.children.count - data.children.results.length}</b>,
                )}
              </small>
            </Link>
          </td>
        </tr>
      ) : null}
    </>
  );
};

const renderRow = (
  data,
  columns,
  itemLink,
  nestingLevel = 0,
  rowKey = (it) => `row-${it.id}`,
) => {

  return data?.length
    ? data.map((it) => (
        <Row
          key={rowKey(it)}
          data={it}
          columns={columns}
          nestingLevel={nestingLevel}
          itemLink={itemLink}
        />
      ))
    : null;
};

const ICONS = {
  asc: "fa-chevron-up",
  desc: "fa-chevron-down",
};

const OrderIcon = ({ direction, className, ...rest }) => (
  <i className={classNames("far", ICONS[direction], className)} {...rest} />
);

const Column = ({
  column,
  orderColumn,
  orderDir,
  changeOrder,
  className: colClassName,
  data,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClick = () => {
    if (column.sort) {
      if (column.name === orderColumn) {
        const newDir = orderDir === "asc" ? "-" + orderColumn : orderColumn;
        changeOrder(newDir);
      } else {
        changeOrder(column.name);
      }
    }
    setIsHovered(false);
  };

  const columnName = getColumnName(column, data);

  const className = classNames(
    `col-${columnName}`,
    colClassName,
    "text-nowrap",
    {
      sortable: column.sort,
    },
  );

  const activeOrdering = orderColumn === columnName;
  const nextDir = activeOrdering && orderDir === "asc" ? "desc" : "asc";

  if (column.sort)
    return (
      <th className={className}>
        <span
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {column.header}

          {column.sort ? (
            <OrderIcon
              direction={activeOrdering && !isHovered ? orderDir : nextDir}
              className={classNames("mx-1", {
                invisible: !isHovered && !activeOrdering,
                "opacity-50": isHovered,
              })}
            />
          ) : null}
        </span>
      </th>
    );
  return <th className={className}>{column.header}</th>;
};

const SearchResults = ({ search }) => (
  <div className="w-100 text-center py-2">
    {strings.formatString(
      strings.components.table.noSearchResults,
      <b>{search}</b>,
    )}
  </div>
);

const NoData = () => (
  <div className="w-100 text-center py-2">
    {strings.components.table.noData}
  </div>
);

const Table = ({
  data,
  columns,
  orderby,
  changeOrder,
  search,
  itemLink,
  rowKey,
  tableClass = "table k-table",
}) => {
  const orderColumn = orderby[0] === "-" ? orderby.slice(1) : orderby;
  const orderDir = orderby[0] === "-" ? "desc" : "asc";
  const hasData = data.length > 0;

  return (
    <>
      <table className={tableClass}>
        <thead>
          <tr>
            {columns
              .filter((it) => it.visible)
              .map((col) => {
                return (
                  <Column
                    key={col.name}
                    column={col}
                    orderColumn={orderColumn}
                    orderDir={orderDir}
                    changeOrder={changeOrder}
                    data={data}
                    className={col.className}
                  />
                );
              })}
          </tr>
        </thead>
        <tbody>{renderRow(data, columns, itemLink, 0, rowKey)}</tbody>
      </table>

      {!hasData && (search ? <SearchResults search={search} /> : <NoData />)}
    </>
  );
};

class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.loadMore = this.loadMore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.elementRef = createRef();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loading } = this.state;
    const { hasMore, scrollParent } = this.props;

    if (loading) {
      return;
    }

    scrollParent.current.onscroll = this.handleScroll;

    const hasSpaceLeft =
      scrollParent.current.clientHeight > this.elementRef.current.clientHeight;

    if (hasSpaceLeft && hasMore) {
      this.loadMore();
    }
  }

  handleScroll({ target }) {
    const scrollPosition =
      target.scrollHeight - target.scrollTop - target.offsetHeight;
    const { loading } = this.state;
    const { hasMore } = this.props;

    if (loading || !hasMore) {
      return;
    }

    if (scrollPosition < 10) {
      this.loadMore();
    }
  }

  async loadMore() {
    const { loadMore } = this.props;

    this.setState({ loading: true }, () => {
      loadMore().then(() => this.setState({ loading: false }));
    });
  }

  render() {
    const { children, tag: givenTag } = this.props;
    const Tag = givenTag || "div";

    return <Tag ref={this.elementRef}>{children}</Tag>;
  }
}

export class InfiniteScrollTable extends React.Component {
  constructor(props) {
    super(props);

    this.tableRef = createRef();
    this.scroll = createRef();
  }

  render() {
    const {
      data,
      columns,
      orderby,
      loadMore,
      hasMore,
      changeOrder,
      search,
      style,
      tableClass = "k-table",
    } = this.props;

    const orderColumn = orderby[0] === "-" ? orderby.slice(1) : orderby;
    const orderDir = orderby[0] === "-" ? "desc" : "asc";
    const hasData = data.length > 0;

    return (
      <div className={"table-fixed-header"} ref={this.tableRef} style={style}>
        <table className={tableClass}>
          <thead>
            <tr>
              {columns
                .filter((it) => it.visible)
                .map((col) => (
                  <Column
                    key={col.name}
                    column={col}
                    orderColumn={orderColumn}
                    orderDir={orderDir}
                    changeOrder={changeOrder}
                  />
                ))}
            </tr>
          </thead>

          <InfiniteScroll
            tag="tbody"
            hasMore={hasMore}
            loadMore={loadMore}
            scrollParent={this.tableRef}
          >
            {hasData &&
              data.map((it) => (
                <Row key={`row-${it.id}`} data={it} columns={columns} />
              ))}
          </InfiniteScroll>
        </table>
        {!hasData && (search ? <SearchResults search={search} /> : <NoData />)}
      </div>
    );
  }
}

export default Table;
