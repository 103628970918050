import { UNAUTHORIZED } from "constants/auth.constants";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { getItemsById } from "./utils";

export const makeListReducer = (reducer, key) =>
  persistReducer({ key, storage, whitelist: ["columns"] }, reducer);

const reducer = (list, defaults = {}) => {
  const initialState = {
    loading: false,
    error: false,
    ready: false,
    items: [],
    itemsById: {},
    count: 0,
    ...defaults,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case list.REQUEST:
        return {
          ...state,
          loading: true,
          ready: false,
        };
      case list.FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          ready: false,
        };
      case list.SUCCESS: {
        const { count, results } = action.data;
        return {
          ...state,
          items: results,
          itemsById: getItemsById(results),
          count: count,
          loading: false,
          ready: true,
        };
      }
      case UNAUTHORIZED:
        return initialState;
      default:
        return state;
    }
  };
};

export default reducer;