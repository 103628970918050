import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/signup.constants";

const initialState = {
  loading: false,
  success: null,
  errorCode: null,
  ready: false,

  code: null,
  background: null,
  name: null,
  confirmed: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SIGNUP_INFO.REQUEST:
    case constants.SIGNUP_SUBMIT.REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case constants.SIGNUP_SUBMIT.SUCCESS:
    case constants.SIGNUP_INFO.SUCCESS: {
      const data = action.data;

      return {
        ...state,
        ...data,
        ready: true,
        loading: false,
        success: true,
      };
    }
    case constants.SIGNUP_SUBMIT.FAILURE:
    case constants.SIGNUP_INFO.FAILURE: {
      return {
        ...state,
        ready: true,
        loading: false,
        success: false,
        error: action?.data,
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export default reducer;