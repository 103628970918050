import "@fortawesome/fontawesome-pro/css/all.css";
import * as Sentry from "@sentry/react";
import "animate.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import React from "react";
import "react-app-polyfill/ie11";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/scss/styles.scss";
import "./style/animations.scss";
import "./style/bootstrap.scss";
import "./style/custom/carousel.scss";
import "./style/custom/react-bootstrap.scss";
import "./style/custom/react-grid-layout.scss";
import "./style/popover.scss";
import "./style/theme.scss";
import "./style/toastify.scss";
import "./style/utils.scss";

import Root from "./pages/root";
import { unregister } from "./registerServiceWorker";
import configureStore from "./store";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// eslint-disable-next-line no-undef
const SENTRY_URL = process.env.REACT_APP_SENTRY_URL || "";

Sentry.init({
  dsn: SENTRY_URL,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  ignoreErrors: ["ChunkLoadError"],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  enabled: Boolean(SENTRY_URL),
});

const { store, persistor } = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<Root store={store} persistor={persistor} />);

unregister();
