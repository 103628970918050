import { PrivateRoute } from "components/router";
import React, { useEffect, lazy } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";

import { getCurrentUser } from "../actions";
import { getInfo } from "../actions/info.actions";
import ErrorBoundary from "../errorBoundary";
import strings, { setLanguage } from "../l10n";
import { SentryRoutes } from "routes";
import { ROLES } from "../userRoles";

const Login = lazy(() => import("./auth"));
const Logout = lazy(() => import("./logout"));
const SignupRoutes = lazy(() => import("./signup"));
const TrainingConfirmationRoutes = lazy(
  () => import("./trainingConfirmation"),
);
const DashboardPage = lazy(() => import("./dashboard"));
const ConfirmationRoutes = lazy(() => import("./confirmation"));
const Marketplace = lazy(() => import("./marketplace"));

const BaseRouter = ({ getCurrentUser, getInfo, user, company }) => {
  useEffect(() => {
    getCurrentUser();
    getInfo();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const errorMessage = urlParams.get("error_message");

    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: false,
      });
    }
  }, [getCurrentUser, getInfo]);

  const {
    language: { dashboard: userLanguage },
  } = user;
  const {
    language: { dashboard: companyLanguage },
  } = company;

  const language =
    userLanguage || companyLanguage || strings.getInterfaceLanguage();

  setLanguage(language);

  return (
    <ErrorBoundary>
      <SentryRoutes key={language}>
        <Route exect path="/login/*" element={<Login />} />
        <Route exect path="/logout" element={<Logout />} />
        <Route path="/signup/*" element={<SignupRoutes />} />
        <Route path="/confirm/*" element={<ConfirmationRoutes />} />
        <Route
          path="/confirm-training/*"
          element={<TrainingConfirmationRoutes />}
        />

        <Route
          path="/marketplace/*"
          element={
            <PrivateRoute requiredRole={[ROLES.content_creator]}>
              <Marketplace />
            </PrivateRoute>
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
      </SentryRoutes>
    </ErrorBoundary>
  );
};

export default connect(
  (state) => {
    const {
      auth: { user },
      company,
    } = state;

    return {
      user,
      company,
    };
  },
  (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    getInfo: () => dispatch(getInfo()),
  }),
)(BaseRouter);
