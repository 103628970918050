import { FORGOT_PASSWORD, UNAUTHORIZED } from "constants/auth.constants";

const initialState = {
  loading: false,
  success: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.data,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export default reducer;