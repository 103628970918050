const responseInterceptor = (instance, onError) => {
  return (response) => {
    const {data, status, config} = response;

    if (!config._currentRetry) config._currentRetry = 0;
    if (!config._currentDelay) config._currentDelay = config.delay;

    if (config.retryStatuses.includes(status)) {
      config._currentRetry += 1;
      config.params.t = config._currentRetry
      if (config._currentRetry > config.retries) {
        return onError ? onError({ response, config }) : Promise.reject({ response, config });
      }
      config._currentDelay = Math.min(config.retryDelay * config._currentRetry, config.maxRetryDelay);
      const jitter = Math.floor(Math.random() * 1000);

      const wait = new Promise((resolve) => {
        setTimeout(() => resolve(), config._currentDelay + jitter);
      })

      return wait.then(() => instance(config));
    }

    return data;
  }
}

export default responseInterceptor;