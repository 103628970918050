import { LOGIN, UNAUTHORIZED } from "constants/auth.constants";

const initialState = {
  loading: false,
  success: null,
  error: null,
  redirect_to: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case LOGIN.SUCCESS: {
      const { redirect_to } = action.data;
      return {
        ...state,
        redirect_to,
        loading: false,
        success: true,
      };
    }
    case LOGIN.FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.data,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export default reducer;