const defaultQuestionsPerWeek = 28;

const daysMap = {
  days: 1,
  weeks: 7,
  months: 30,
  years: 365,
};

const roundSteps = {
  weeks: 56, // days
  months: 180, // days
};

const getRecommendedDeadline = (newTopic, allTopics = []) => {
  const totalPublishedQuestions = allTopics.reduce((a, b) => {
    if (b.type === "topic") {
      return a + b.num_published_questions;
    }
    if (b.type === "course") {
      return (
        a + b.topics.reduce((_a, _b) => _a + _b.num_published_questions, 0)
      );
    }
    return 0;
  }, 0);

  let numPublishedQuestions = 0;

  if (newTopic.type === "topic") {
    numPublishedQuestions = newTopic.num_published_questions;
  }

  if (newTopic.type === "course") {
    numPublishedQuestions = newTopic.topics.reduce(
      (a, b) => a + b.num_published_questions,
      0,
    );
  }

  let recommendedDeadline =
    Math.ceil(
      (totalPublishedQuestions + numPublishedQuestions) /
        defaultQuestionsPerWeek,
    ) * 7; // days

  Object.keys(roundSteps)
    .reverse()
    .forEach((key) => {
      if (recommendedDeadline > roundSteps[key]) {
        recommendedDeadline =
          Math.ceil(recommendedDeadline / daysMap[key]) * daysMap[key];
      }
    });
  return Math.max(recommendedDeadline, 1);
};

const getDeadlineSettings = (deadlineInDays) => {
  let values = {
    text_amount: deadlineInDays,
    text_period: "days",
  };

  Object.keys(roundSteps)
    .reverse()
    .forEach((key) => {
      if (
        deadlineInDays > roundSteps[key] &&
        deadlineInDays % daysMap[key] === 0
      ) {
        values = {
          text_amount: deadlineInDays / daysMap[key],
          text_period: key,
        };
      }
    });

  return values;
};

const getDaysToCertify = (textAmount, textPeriod) => {
  const daysMap = {
    days: 1,
    weeks: 7,
    months: 30,
    years: 365,
  };

  return textAmount * daysMap[textPeriod];
};

export { getRecommendedDeadline, getDeadlineSettings, getDaysToCertify };
