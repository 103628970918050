export const baseUrl = window.webapiUrl || "/webapi";

export const fetchAll = async (func, args = {}) => {
  let page = 0;
  const pagesize = 100;
  let results = [];
  let response;

  do {
    response = await func({ ...args, page, pagesize });
    results = [...results, ...response.results];
    page += 1;
  } while (response?.next);

  return { ...response, results };
};
