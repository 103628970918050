import axios from "axios";
import errorHandler, { makeError } from "./errorHandler";
import responseInterceptor from "./responseInterceptor";

const instance = axios.create({
  baseURL: window.webapiUrl || "/webapi",
  xsrfCookieName: "knowingo-xsrf",
  xsrfHeaderName: "knowingo-xsrf",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  paramsSerializer: { indexes: null },
  // Custom config below
  retryStatuses: [202],
  retries: 30,
  retryDelay: 2000,
  maxRetryDelay: 30000,
});

instance.interceptors.response.use(
  responseInterceptor(instance, ({ response, config }) =>
    makeError("TooManyRetries", { status: response.status, config }),
  ),
  errorHandler,
);

export default instance;
