import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/company.constants";

const initialState = {
  id: null,
  found: false,
  error: false,
  name: "",
  features: {},
  loading: false,
  language: {},
  config: {
    client: {
      content: {},
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.COMPANY.REQUEST:
    case constants.COMPANY_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.COMPANY.SUCCESS:
    case constants.COMPANY_UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        found: true,
        ...action.data,
      };
    case constants.COMPANY.FAILURE:
    case constants.COMPANY_UPDATE.FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export default reducer;