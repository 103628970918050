import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DropdownListBase from "react-widgets/DropdownList";

import { Button } from "../button";
import styles from "./dropdown.module.scss";

const DropdownList = (props) => {
  const {
    allowClear,
    onChange,
    onClear,
    value,
    alwaysClear,
    className,
    error,
    icon,
    defaultValue,
    label,
    ...rest
  } = props;
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    setCurrentValue(defaultValue || value);
  }, [defaultValue, value]);

  useEffect(() => {
    if (!alwaysClear) {
      setCurrentValue(value);
    }
  }, [alwaysClear, value]);

  const onChangeWrapper = (value) => {
    if (!alwaysClear) {
      setCurrentValue(value);
    }
    onChange(value);
  };

  const handleClearButtonClick = () => {
    if (onClear) {
      onClear();
    } else {
      setCurrentValue(null);
      onChange(null);
    }
  };

  return (
    <div
      className={classNames({
        [styles.hasError]: error,
      }, "d-flex flex-column w-100 position-relative")}
    >
      {label && <label>{label}</label>}

      <div className={classNames(styles.dropdownList, className)}>
        {icon && <i className={`far fa-${icon} me-2`} />}

        <DropdownListBase
          onChange={onChangeWrapper}
          value={currentValue}
          {...rest}
        />

        {currentValue && allowClear ? (
          <Button
            accent={"transparent"}
            className={`${styles.clearButton}`}
            onClick={handleClearButtonClick}
            icon={"times"}
          />
        ) : null}
      </div>

      {error ? <div className={"my-2 text-red font-sm"}>{error}</div> : null}
    </div>
  );
};

export default DropdownList;
